import { useAppContext } from '@/core/App/AppContext'
import Link from '@/core/Link'
import useGraphQLQuery from '@/theme/lib/useGraphQLQuery'
import { gql } from 'graphql-request'
import { useInView } from 'react-intersection-observer'
import Button from '../Button'
import MainSlider, { SwiperSlide } from '../MainSlider'
import { TitleTile } from '../TitleTile'

const ButtonLink = (props) => <Button as="a" {...props} />

const sliderBreakpoints: any = {
  0: {
    slidesPerView: 1,
  },
  600: {
    slidesPerView: 2,
  },
  900: {
    slidesPerView: 3,
  },
}
// tags
const HomePageReviewsBase = ({}) => {
  const [{ data, loading }, request, { reset }] = useGraphQLQuery(
    gql`
      query getHomePageBlogs {
        getTestimonialsData {
          testimonial_id
          name
          text
          location
        }
      }
    `,
    {},
    {
      immediate: true,
      authorized: false,
      fn: (d) => {
        return d.getTestimonialsData || []
      },
    }
  )

  if ((data || []).length == 0 && !loading) return null

  return (
    <MainSlider
      spaceBetween={50}
      slidesPerView={3}
      autoHeight={true}
      className="customer-review-container"
      breakpoints={sliderBreakpoints}
    >
      {data &&
        data.map((data) => (
          <SwiperSlide
            key={data.testimonial_id}
            className="content customer-review"
          >
            <p>{data.text}</p>
            <div className="author">
              <div className="author-content">
                <div className="title-box">
                  <a href="#" title="" target="_blank">
                    {data.name}
                  </a>
                  <div className="review-rate">
                    5
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 6 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.00809 0.834882L3.70517 2.9803H5.961L4.136 4.30624L4.83309 6.45165L3.00809 5.12571L1.18309 6.45165L1.88017 4.30624L0.055174 2.9803H2.311L3.00809 0.834882Z"
                        fill="#818B5A"
                      />
                    </svg>
                  </div>
                  {/* <div className="job">{data.location}</div> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
    </MainSlider>
  )
}

export default function HomePageReviewsAsync(props) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <>
      <TitleTile title="Customer Reviews" getRef={ref} />
      {inView ? <HomePageReviewsBase /> : null}
    </>
  )
}
