import React, { Children, ReactNode, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type SwiperClass from 'swiper/types/swiper-class'
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
} from 'swiper'

import 'swiper/swiper.scss'
import './main-slider.scss'
// import "swiper/components/navigation/navigation.scss";
import 'swiper/components/pagination/pagination.scss'
// import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/effect-fade/effect-fade.scss'
import { TitleTile } from '@/theme/components/TitleTile'
import useEffectAfterMount from '@/services/helpers/useEffectAfterMount'

SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay])

interface MainSlider {
  spaceBetween: any
  slidesPerView: any
  children?: any
  fade?: Boolean
  title?: ReactNode
  className?: any
  loading?: ReactNode
  delay?: any
  autoHeight?: boolean
  pagination?: boolean
  navigation?: boolean
}
const MainSlider = (props: MainSlider) => {
  const {
    spaceBetween,
    title = '',
    fade,
    className,
    slidesPerView,
    loading,
    children,
    delay = null,
    breakpoints = null,
    autoHeight = undefined,
    pagination = true,
    navigation = false,
  } = props

  const ref = useRef<SwiperClass>(null)

  useEffectAfterMount(() => {
    ref.current.init()
  }, [slidesPerView, JSON.stringify(loading)])
  return (
    <>
      {title && <TitleTile title={title} />}
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        className={className}
        navigation={navigation}
        onInit={(swiper) => {
          ref.current = swiper
        }}
        effect={fade ? 'fade' : 'slide'}
        autoHeight={autoHeight}
        autoplay={
          delay
            ? {
                delay: delay,
                disableOnInteraction: false,
              }
            : false
        }
        pagination={pagination && {
          clickable: true,
        }}
        breakpoints={breakpoints}
      >
        {children}
        {/* {React.Children.map(children, (child, key) => {
          return React.createElement(SwiperSlide, { key }, child);
        })} */}
      </Swiper>
    </>
  )
}

export { SwiperSlide }

export default MainSlider
