import useGraphQLQuery from '@/theme/lib/useGraphQLQuery'
import { gql } from 'graphql-request'
import product_items from 'src/graphql/queries/product/product_items'
import ProductSlider from '../ProductSlider'

export default (props) => {
  const [{ data, loading }, request, { reset }] = useGraphQLQuery(
    gql`query getProducts($filter: ProductAttributeFilterInput
        $pageSize: Int
        $currentPage: Int
        ) {
          products(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
              items {
                  ${product_items({})}
              }
              total_count
          }
      }`,
    {
      filter: props.filter,
      currentPage: props.currentPage,
      pageSize: props.pageSize,
    },
    { immediate: true, authorized: false }
  )

  const products = data?.products.items || []

  if (products.length == 0 && !loading) return null
  return (
    <ProductSlider title={props.title} items={products} loading={loading} />
  )
}
