import { Image } from '@/theme/components/Image'
import Link from '@/core/Link'
import './tv.scss'
import { useAppContext } from '@/core/App/AppContext'

export const TvComponent = (props) => {
  const { videos } = props
  const [{ isMobile }] = useAppContext()
  return (
    <div className={'tv-video-grid'}>
      {/* {JSON.stringify({ videos })} */}
      {(videos || []).slice(0, 3).map((data, index) => {
        return (
          <Link key={data.link} href={`/video/${data.url_key}`}>
            <Image label="" url={data.thumbnail} />
            {isMobile && (
              <span className="play-btn">
                <i className="fa fa-play"></i>
              </span>
            )}
          </Link>
        )
      })}
    </div>
  )
}
