import { useEffect, useRef } from 'react';

export default function useEffectAfterMount(fn, deps) {
  const isMounted = useRef(false);
  useEffect(() => {
    let val;
    if (isMounted.current) {
      val = fn();
    }
    isMounted.current = true;
    return () => {
      if (typeof val == 'function') val();
    };
  }, deps);
}
