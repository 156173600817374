import { useAppContext } from "@/core/App/AppContext";
import Link from "@/core/Link";
import useGraphQLQuery from "@/theme/lib/useGraphQLQuery";
import { gql } from "graphql-request";
import { useInView } from "react-intersection-observer";
import Button from "../Button";
import MainSlider, { SwiperSlide } from "../MainSlider/index";
import { TitleTile } from "../TitleTile";
import './homepageblog.scss';
import blogStyle from './blog-posts.module.css';

const ButtonLink = (props) => <Button as="a" {...props} />;

const BlogPostItem = ({ data }) => {
  return (
    <div className={blogStyle.blog_post_item}>
      <img
        className="blog-image"
        src={`${process.env.APP_CDN_URL}/media/mageplaza/blog/post/${data.image}`}
        alt={data.name}
        loading="lazy"
      />
      <h3 className="blog-name">{data.name}</h3>
      <Button>
        <a
          className="blog-button"
          href={`/blog/post/${data.url_key}`}
          target="_blank"
        >
          Read more
        </a>
      </Button>
    </div>
  )
}

// tags
const HomePageBlogBase = ({ }) => {
  const [{ data, loading }, request, { reset }] = useGraphQLQuery(
    gql`
      query getHomePageBlogs {
        mpBlogPosts(action: "get_homeblog_post_list") {
          items {
            image
            post_id
            name
            url_key
          }
        }
      }
    `,
    {},
    {
      immediate: true,
      authorized: false,
      fn: (d) => {
        return d.mpBlogPosts?.items || [];
      },
    }
  );

  const [{ isMobile }] = useAppContext();

  if ((data || []).length == 0 && !loading) return null;


  return (
    <MainSlider
      spaceBetween={50}
      slidesPerView={isMobile ? 1 : 3}
      autoHeight={false}
      // pagination={{
      //   clickable: true,
      // }}
      className="blog-container"
    >
      {data &&
        data.map((data, key) => (
          <SwiperSlide key={data.post_id} className='blog'>
            <BlogPostItem data={data} />
          </SwiperSlide>
        ))}
    </MainSlider>
  );
};

export default function HomePageBlogAsync(props) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <>
      <TitleTile title="Most Recent Blogs" getRef={ref} />
      {inView ? <HomePageBlogBase /> : null}
    </>
  );
}
