import Link from "@/core/Link";
import { useInView } from "react-intersection-observer";
import Button from "../Button";
import { TitleTile } from "../TitleTile";
import { TvComponent } from "../TvComponent";

const ButtonLink = (props) => <Button as="a" {...props} />;

export default function HomePageTvComponentAsync(props) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  return (
    // <div className="moha-tv-container">
    <>
      <TitleTile title="moha: TV" getRef={ref} />
      {/* <h4 style={{ paddingBottom:"10px", marginTop:"-20px",textAlign:"center"}}>Our Past &amp; Current Campaigns</h4> */}
      {entry && <TvComponent videos={props.videos} />}
      {/* <div className="tv-button-wrapper">
        <Link component={ButtonLink} className="tv-button" href="/tv">
          moha: TV
        </Link>
      </div> */}
    </>
    // </div>
  );
}
