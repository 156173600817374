import { useInView } from 'react-intersection-observer'
import ProductListBase from './ProductListBase'

export default function ProductListAsync({
  filter,
  title,
  currentPage = 1,
  pageSize = 10,
}) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <div ref={ref} className="product-list">
      {inView ? (
        <ProductListBase
          title={title}
          filter={filter}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      ) : (
        <div
          style={{
            minHeight: '100px',
            width: '100%',
            display: 'block',
          }}
        ></div>
      )}
    </div>
  )
}
