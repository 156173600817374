import Link from '@/core/Link'
import React from 'react'
import './LoadMore.scss'

const LoadMore = ({src}) => {
  return (
    <Link href={src} className='load-more-button'>
        VIEW ALL
    </Link>
  )
}

export default LoadMore